import React from 'react';
const Header = ({ title = `${process.env.REACT_APP_PROJECT_TITLE}LINE発注` }) => {
    return (
        <header className="line-header bg-green d-flex align-items-center justify-content-center">
            <div className="text-white lg">{title}</div>
        </header>
    );
};

export default Header;
