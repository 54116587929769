import React, { useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { Navigate, useLocation } from 'react-router-dom';
import Header from '../../components/Header';
import './style.scss';

const Login = () => {
    const location = useLocation();
    const accessToken = localStorage.getItem('line:accessToken');

    useEffect(() => {
        console.log('useLocation', location);
        const params = new URLSearchParams(location.search);
        const next = params.get('next');
        if (next) {
            localStorage.setItem('next', next);
        }
    }, []);

    const handleAuthLine = () => {
        window.location.href = `${process.env.REACT_APP_SERVER_API_LINE}/auth/line`
    };
    
    if (accessToken) return <Navigate to={{ pathname:'/line' }} />;
    return (
        <Container>
            <Header />
            <div className="login-wrapper">
                <div className="login-content text-center">
                    <div className="login-title">LINE ログイン</div>
                    <a
                        className="login-btn d-block align-self-center"
                        onClick={handleAuthLine}
                    >
                        <div className='flex items-center justify-center'>
                            <img
                                src="/images/line/line-messenger.svg"
                                className="mr-3"
                            />
                            <p className='m-0'>LINEログイン</p>
                        </div>
                    </a>
                </div>
            </div>
        </Container>
    );
};

export default Login;
