import React, { useState, useEffect } from 'react';
import './dashboard.css';
import Vector from '../../assets/icons/Vector.png';
import IconWarning from '../../assets/icons/Icon-warning.png';

import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import ProgressBar from './components/Progressbar';
import topPageService from '../../services/topPageService';
import { useNavigate } from 'react-router-dom';
import NewsModal from './components/newsModal';
import Modal from 'react-modal';
import NewsService from 'services/newsService';
import { formatDateTime } from 'utils/helper/dateHelper';
import purchaseRequestService from 'services/purchaseRequestService';
import { isAdmin } from 'utils/helper/workerHelper';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

Modal.setAppElement('#root');

declare let window: any;

interface IPurchaseRequest {
    content: string;
    custom_data: {status: string, maker_worker_name: string, name: string},
    id: number;
    publish_at: string;
    resource_id: number;
    resource_type: string;
    title: string;
}

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        zIndex: 1000,
        backgroundColor: '#FFFFFF',
        position: 'fixed',
    },
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#C7DCF5',
        color: '#1C1E21',
    },
    [`&.${tableCellClasses.body}`]: {
        fontFamily: 'Zen Kaku Gothic Antique',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '24px',
        color: '#1C1E21',
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const Dashboard = () => {
    const [hide, setHide] = useState(false);
    const listProgress = ['0%', '0%', '0%'];
    const [openNewsModal, setOpenNewsModal] = useState(false);
    const [newsList, setNewsList] = useState<any>(null);
    const [news, setNews] = useState<any>(null);
    const [requestsList, setRequestsList] = useState<any>(null);
    const [progressList, setProgressList] = useState<any>(listProgress);
    const [progressListNumber, setProgressNumber] = useState<any>([0, 0, 0]);
    const [purchaseRequests, setPurchaseRequests] = useState<IPurchaseRequest[]>([]);
    const [openPrModal, setOpenPrModal] = useState(false);

    const navigate = useNavigate();
    const today = formatDateTime(new Date(), 'yyyy-MM-DD');
    const { userData } = useSelector((state: RootState) => state.users);
    const isAllow = isAdmin(userData);

    const handleClose = () => {
        setOpenNewsModal(false);
    };

    function getFormattedDate(dateString) {
        let date = new Date(dateString);
        var year = date.getFullYear();
        var month = (1 + date.getMonth()).toString();
        month = month.length > 1 ? month : '0' + month;
        var day = date.getDate().toString();
        day = day.length > 1 ? day : '0' + day;
        return year + '-' + month + '-' + day;
    }

    const getListNews = async () => {
        try {
            // const response = await topPageService.getListNews()
            const response = await NewsService.getListNews({
                page: 1,
                per_page: 0,
            });
            if (response !== null && response !== undefined) {
                const todayNews = response.news.filter(
                    (item) => item.publish_at === today && item.resource_type !== "PurchaseRequest",
                );
                const otherDayNews = response.news.filter(
                    (item) => item.publish_at !== today && item.resource_type !== "PurchaseRequest",
                );
                setNewsList([...todayNews, ...otherDayNews]);
                // setNewsList(response.top_page)
                const listPrNews = response?.news?.filter((item: { resource_type: string; custom_data: { status: string; }; }) => item?.resource_type === "PurchaseRequest" && item?.custom_data?.status !== "denied");
                setPurchaseRequests(listPrNews);
            }
        } catch (err) {
            console.log('err', err);
        }
    };

    const getListRequest = async () => {
        try {
            const response = await topPageService.getRequest();
            if (response !== null && response !== undefined) {
                response.top_page.map((request) => {
                    if (
                        request.created_at !== undefined &&
                        request.created_at !== null
                    ) {
                        request.created_at = getFormattedDate(
                            request.created_at,
                        );
                    }
                    if (
                        request.due_date !== undefined &&
                        request.due_date !== null
                    ) {
                        request.due_date = getFormattedDate(request.due_date);
                    }
                });
                setRequestsList(response.top_page);
            }
        } catch (err) {
            console.log('err', err);
        }
    };

    const getMax = (object) => {
        return Object.keys(object).filter((x) => {
            return object[x] == Math.max.apply(null, Object.values(object));
        });
    };

    const getListProgress = async () => {
        try {
            const response = await topPageService.getProgress();
            // let response = {complete_project: 6,
            //   inprogress_project: 5,
            //   negotiating_project: 3}
            // console.log(response)
            if (response !== null && response !== undefined) {
                let obj = response;
                let listKeySort = getMax(obj);

                let a = '';
                let b = '';
                let c = '';
                for (const [key, value] of Object.entries(obj)) {
                    // console.log(`${key}: ${value}`);
                    if (
                        key == 'negotiating_project' &&
                        key == listKeySort[0] &&
                        value !== 0
                    ) {
                        a = '100%';
                        b = `${
                            (obj.inprogress_project / obj.negotiating_project) *
                            100
                        }%`;
                        c = `${
                            (obj.complete_project / obj.negotiating_project) *
                            100
                        }%`;
                    }
                    if (
                        key == 'inprogress_project' &&
                        key == listKeySort[0] &&
                        value !== 0
                    ) {
                        b = '100%';
                        a = `${
                            (obj.negotiating_project / obj.inprogress_project) *
                            100
                        }%`;
                        c = `${
                            (obj.complete_project / obj.inprogress_project) *
                            100
                        }%`;
                    }
                    if (
                        key == 'complete_project' &&
                        key == listKeySort[0] &&
                        value !== 0
                    ) {
                        c = '100%';
                        b = `${
                            (obj.inprogress_project / obj.complete_project) *
                            100
                        }%`;
                        a = `${
                            (obj.negotiating_project / obj.complete_project) *
                            100
                        }%`;
                    }
                }
                // console.log([a,b,c])
                // console.log([obj.negotiating_project, obj.inprogress_project, obj.complete_project])
                setProgressList([a, b, c]);
                setProgressNumber([
                    obj.negotiating_project,
                    obj.inprogress_project,
                    obj.complete_project,
                ]);
            }
        } catch (err) {
            console.log('err', err);
        }
    };

    const convertDateToJapan = (dateTime) => {
        let newDateTime = dateTime.replace(/ /g, 'T');
        const date = new Date(newDateTime);
        var lang = 'en';
        let year = date.toLocaleString(lang, { year: 'numeric' });
        let month = date.getMonth() + 1;
        let day = date.toLocaleString(lang, { day: 'numeric' });
        const textDate = `${year}年${month}月${day}日`;
        return textDate;
    };

    const updatePrApproveDeny = async (payload: string) => {
        if(!news?.resource_id) return;
        const params = {
            status: payload,
          }
        try {
            await purchaseRequestService.updatePRApproveDeny(news?.resource_id, params);
            getListNews();
        } catch (error) {
            console.log(error);
        }
    }

    const handleAgreeSendApiFirst = () => {
        updatePrApproveDeny("approved");
        setOpenPrModal(false);
    }
    const handleRefuseSendApiFirst = () => {
        updatePrApproveDeny("denied");
        setOpenPrModal(false);
    }

    useEffect(() => {
        getListNews();
        getListRequest();
        getListProgress();
    }, []);

    useEffect(() => {
        let path = window.location.pathname;
        if (path == '/login') {
            setHide(true);
        }
    }, []);

    return (
        <div className="dashboard">
            <div className="dashboard-content d-flex flex-column">
                <div className="dashboard-header">
                    <p>一生懸命。</p>
                    <p>ベターな事はするな。ベストで行け。</p>
                </div>

                <div className="dashboard-news d-flex flex-row">
                    <div className="dashboard-news-container d-flex flex-column">
                        <div className="dashboard-news-title">お知らせ</div>
                        <div className={`dashboard-news-content p-4 flex flex-col justify-between ${purchaseRequests?.length > 3 && isAllow ? "dashboard-news-content1" : "!h-fit"}`}>
                            <div>
                                {newsList !== undefined &&
                                newsList !== null &&
                                newsList.length > 0 ? (
                                    newsList.map((item, ind) => {
                                        if (ind < 2)
                                            return (
                                                <div className="flex items-start">
                                                    <div className="mr-2">
                                                        <p className="text-[14px] mb-0 text-[#1c1e21] leading-[24px] font-[500]">
                                                            {convertDateToJapan(
                                                                item.publish_at,
                                                            )}
                                                        </p>
                                                    </div>
                                                    <div className="news-content-title">
                                                        <p className="text-[#406fe5] text-[16px] truncate w-full leading-[24px] mb-0">
                                                            {item?.title}
                                                        </p>
                                                        <div className="news-inf-content-ctn d-flex flex-row">
                                                            <p
                                                                style={{
                                                                    width: 'calc(100% - 85px)',
                                                                    color: 'rgba(0, 0, 0, 0.7)',
                                                                }}
                                                                className="text-[14px] truncate leading-[24px] mb-0"
                                                            >
                                                                {item?.content}
                                                            </p>
                                                            <div
                                                                className="text-[#195192] text-[14px] font-[500] cursor-pointer underline mb-0"
                                                                onClick={() => {
                                                                    setOpenNewsModal(
                                                                        true,
                                                                    );
                                                                    setNews(
                                                                        item,
                                                                    );
                                                                }}
                                                            >
                                                                もっと見る
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                    })
                                ) : (
                                    <div className="empty-news-list d-flex flex-column">
                                        お知らせはありません
                                    </div>
                                )}
                                <div className='mt-[18px]'>
                                    {
                                        isAllow && purchaseRequests?.length > 0 && purchaseRequests?.map((item, index) => (
                                            <div className="flex items-start" key={index}>
                                                <div className="mr-2">
                                                    <p className="text-[14px] mb-0 text-[#1c1e21] leading-[24px] font-[500]">
                                                        {convertDateToJapan(
                                                            item.publish_at,
                                                        )}
                                                    </p>
                                                </div>
                                                <div className="news-content-title">
                                                    <p className="text-[#406fe5] text-[16px] truncate w-full leading-[24px] mb-0">
                                                        {item?.custom_data?.name}
                                                    </p>
                                                    <div className="news-inf-content-ctn d-flex flex-row">
                                                        <p
                                                            style={{
                                                                width: 'calc(100% - 85px)',
                                                                color: 'rgba(0, 0, 0, 0.7)',
                                                            }}
                                                            className="text-[14px] truncate leading-[24px] mb-0"
                                                        >
                                                            {item?.title}
                                                        </p>
                                                        <div
                                                            className="text-[#195192] text-[14px] font-[500] cursor-pointer underline mb-0"
                                                            onClick={() => {
                                                                setOpenPrModal(
                                                                    true,
                                                                );
                                                                setNews(
                                                                    item,
                                                                );
                                                            }}
                                                        >
                                                            もっと見る
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                            {newsList !== undefined &&
                                newsList !== null &&
                                newsList.length > 0 && (
                                    <div className="news-see-more-ctn d-flex flex-column">
                                        <div
                                            className="news-see-more mt-[10px] !mb-0 d-flex flex-column"
                                            onClick={() => navigate('/news')}
                                        >
                                            もっと見る
                                        </div>
                                    </div>
                                )}
                        </div>
                        <Modal
                            contentLabel="news modal"
                            isOpen={openNewsModal}
                            onRequestClose={handleClose}
                            style={customStyles}
                        >
                            <NewsModal
                                type="news"
                                close={handleClose}
                                news={news}
                                convertDateToJapan={convertDateToJapan}
                            ></NewsModal>
                        </Modal>
                        <Modal
                            contentLabel="pr modal"
                            isOpen={openPrModal}
                            onRequestClose={() => setOpenPrModal(false)}
                            style={customStyles}
                        >
                            <NewsModal
                                type="pr"
                                close={() => setOpenPrModal(false)}
                                news={news}
                                convertDateToJapan={convertDateToJapan}
                                handleAgreeSendApiFirst={handleAgreeSendApiFirst}
                                handleRefuseSendApiFirst={handleRefuseSendApiFirst}
                                isAllowApproveDeny={isAllow}
                            ></NewsModal>
                        </Modal>
                    </div>

                    <div className="dashboard-news-container d-flex flex-column">
                        <div className="dashboard-news-title">今月進捗</div>
                        <div className="dashboard-news-content progress-chart d-flex flex-column">
                            <div className="progress-chart-item d-flex flex-row">
                                <div className="title-progress">
                                    見込み案件{' '}
                                </div>
                                <div className="custom-progress d-flex flex-row">
                                    <ProgressBar
                                        width={progressList[0]}
                                    ></ProgressBar>
                                    <label>{progressListNumber[0]}</label>
                                </div>
                            </div>
                            <div className="progress-chart-item d-flex flex-row">
                                <div className="title-progress">
                                    実施中案件数
                                </div>
                                <div className="custom-progress d-flex flex-row">
                                    <ProgressBar
                                        width={progressList[1]}
                                    ></ProgressBar>
                                    <label>{progressListNumber[1]}</label>
                                </div>
                            </div>
                            <div className="progress-chart-item d-flex flex-row">
                                <div className="title-progress">完了案件</div>
                                <div className="custom-progress d-flex flex-row">
                                    <ProgressBar
                                        width={progressList[2]}
                                    ></ProgressBar>
                                    <label>{progressListNumber[2]}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <div className='dashboard-warning d-flex flex-column'>
            <div className='dashboard-warning-title d-flex flex-row'>
              <div className='dashboard-news-title'>アラート</div>
              <img width={"20px"} height={"20px"} src={IconWarning} style={{marginTop: "14px"}}></img>
            </div>

            <div className='dashboard-news-content' style={{height: "104px"}}>
              <div className='dashboard-news-item d-flex flex-row' style={{margin: "0 16px 0 16px", height: "50%"}}>
                <div className='d-flex flex-row'>
                  <p className='news-date notice'>🚨◯◯案件　△△工程2日遅れ</p>
                </div>
                <div className='d-flex flex-row'>
                  <img width={"7px"} height={"14px"} src={Vector}></img>
                </div>
              </div>

              <div className='dashboard-news-item d-flex flex-row' style={{margin: "0 16px 0 16px", height: "50%"}}>
                <div className='d-flex flex-row'>
                  <p className='news-date notice'>⚠️◯◯案件　クレーム1件</p>
                </div>
                <div className='d-flex flex-row'>
                  <img width={"7px"} height={"14px"} src={Vector}></img>
                </div>
              </div>

            </div>
          </div> */}

                {/* <div className='dashboard-news d-flex flex-row'>
            <div className='dashboard-news-container d-flex flex-column' style={{width: "100%"}}>
              <div className='dashboard-news-title'>依頼・承認待ち</div>

              <div className='dashboard-news-approval'>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell className='text-overflow' align="center">依頼者</StyledTableCell>
                      <StyledTableCell className='text-overflow' align="center">登録日</StyledTableCell>
                      <StyledTableCell className='text-overflow' align="center">期限</StyledTableCell>
                      <StyledTableCell className='text-overflow' align="center">案件</StyledTableCell>
                      <StyledTableCell className='text-overflow' align="center">内容</StyledTableCell>
                      <StyledTableCell className='text-overflow' align="center">操作</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {requestsList!==null && requestsList.map((row, ind) => (
                      <StyledTableRow key={ind}>
                        <StyledTableCell className='text-overflow' align="center" component="th" scope="row">
                          {row.by_worker?.name}
                        </StyledTableCell>
                        <StyledTableCell className='text-overflow' align="center">{row.created_at}</StyledTableCell>
                        <StyledTableCell className='text-overflow' align="center">{row.due_date}</StyledTableCell>
                        <StyledTableCell className='text-overflow' align="center">{row.project.name}</StyledTableCell>
                        <StyledTableCell className='text-overflow' align="center">{row.content_type !== undefined && row.content_type}</StyledTableCell>
                        <StyledTableCell className='text-overflow' align="center">
                          <button className='button-tb' style={{width: "72px"}}>確認</button>
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              </div>
            </div>
          </div> */}
            </div>
        </div>
    );
};
export default Dashboard;
