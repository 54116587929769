import fetch from './api/lineConfig';

const API = {
    LOGIN: () => `/line/login`,
    LOGOUT: () => `/line/logout`,
    LOGIN_LINE: () => '/auth/line',
};

export default class AuthServiceLine {
    static login = (params: any) => fetch.post(API.LOGIN(), params);
    static logout = () => fetch.delete(API.LOGOUT(), {});
    static login_line = () => fetch.get(API.LOGIN_LINE(), {});
}
