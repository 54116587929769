import Api from './api/userApi';
import pick from 'lodash/pick';

const getMe = async () => {
    let storage = localStorage.getItem('user');
    const user = storage !== null ? JSON.parse(storage) : null;
    if (
        (user.access_token !== undefined && user.access_token !== null) ||
        (user.session_token !== undefined && user.session_token !== null)
    ) {
        try {
            if (user.access_token !== undefined && user.access_token !== null) {
                const response = await Api().get('profiles', {
                    headers: { Authorization: `Bearer ${user.access_token}` },
                });
                return response.data;
            }
        } catch (err) {
            // window.location.href = '/login';
            return null;
        }
    } else {
        window.location.href = '/login';
    }
};

const getUser = async (id) => {
    let storage = localStorage.getItem('user');
    const user = storage !== null ? JSON.parse(storage) : null;
    if (
        (user.access_token !== undefined && user.access_token !== null) ||
        (user.session_token !== undefined && user.session_token !== null)
    ) {
        try {
            if (user.access_token !== undefined && user.access_token !== null) {
                const response = await Api().get(`profiles/?id=${id}`, {
                    headers: { Authorization: `Bearer ${user.access_token}` },
                });
                return response.data;
            }
        } catch (err) {
            // window.location.href = '/login';
            return null;
        }
    } else {
        window.location.href = '/login';
    }
};

const getListUser = async (body) => {
    let storage = localStorage.getItem('user');
    const user = storage !== null ? JSON.parse(storage) : null;
    let data = pick(body, [
        'email',
        'worker_name',
    ]);
    const params = new URLSearchParams(data);
    if (
        (user.access_token !== undefined && user.access_token !== null) ||
        (user.session_token !== undefined && user.session_token !== null)
    ) {
        try {
            if (user.access_token !== undefined && user.access_token !== null) {
                return await Api()
                    .get(`?${params.toString()}`, {
                        headers: {
                            Authorization: `Bearer ${user.access_token}`,
                        },
                    })
                    .then((response) => {
                        if (response.data) {
                            return response.data;
                        }
                    });
            }
        } catch (err) {
            window.location.href = '/login';
            return null;
        }
    } else {
        window.location.href = '/login';
    }
};

const createUser = async (body) => {
    let storage = localStorage.getItem('user');
    const user = storage !== null ? JSON.parse(storage) : null;
    let data = pick(body, [
        'email',
        'password',
        'password_confirmation',
        'worker_id',
        'line_bot_friend_id',
        'role'
    ]);
    if (
        (user.access_token !== undefined && user.access_token !== null) ||
        (user.session_token !== undefined && user.session_token !== null)
    ) {
        return await Api()
            .post('', data, {
                headers: { Authorization: `Bearer ${user.access_token}` },
            })
            .then((response) => {
                if (response.data) {
                    return response.data;
                }
            });
    } else {
        window.location.href = '/login';
    }
};

const updateUser = async (body, id) => {
    let data = pick(body, ['email', 'worker_id', 'line_bot_friend_id', 'role']);
    data.password = body.password || undefined;
    data.password_confirmation = body.password_confirmation || undefined;

    let storage = localStorage.getItem('user');
    const user = storage !== null ? JSON.parse(storage) : null;
    if (
        (user.access_token !== undefined && user.access_token !== null) ||
        (user.session_token !== undefined && user.session_token !== null)
    ) {
        return await Api()
            .put(`${id}`, data, {
                headers: {
                    Authorization: `Bearer ${user.access_token}`,
                },
            })
            .then((response) => {
                if (response.data) {
                    return response.data;
                }
            });
    } else {
        window.location.href = '/login';
    }
};

const deleteUser = async (id) => {
    let storage = localStorage.getItem('user');
    const user = storage !== null ? JSON.parse(storage) : null;
    const params = {
        id: id,
    };
    if (user.access_token !== undefined && user.access_token !== null) {
        try {
            return await Api()
                .delete(`${id}`, {
                    headers: { Authorization: `Bearer ${user.access_token}` },
                    params: params,
                })
                .then((response) => {
                    if (response.data) {
                        return response.data;
                    }
                });
        } catch (err) {
            window.location.href = '/login';
            return null;
        }
    } else {
        window.location.href = '/login';
    }
};

const getListConfiguration = async () => {
    try {
        return await Api()
            .get(`configurations`)
            .then((response) => {
                if (response.data) {
                    return response.data;
                }
            });
    } catch (err) {
        window.location.href = '/login';
        return null;
    }
};

const userService = {
    getMe,
    getListUser,
    createUser,
    deleteUser,
    updateUser,
    getListConfiguration,
    getUser,
};

export default userService;
